import Footer from '../Footer';
import Header from '../Header';
export default function Datenschutz() {
  return (
    <>
      <Header />
      <div className="container pt-50">
        <div className="row">
          <h1 className="mb-50">
            Diese Datenschutzerklärung gilt für die Dienste und Websites:
            www.einfachaktien.de
          </h1>
          <div className="col-md-12" id="datenschutz">
            <p>
              Wenn Sie Dienste von Damantis nutzen, vertrauen Sie uns Ihre Daten
              an. Wir sind uns der großen Verantwortung bewusst und setzen alles
              daran, den Schutz Ihrer Daten zu gewährleisten und Ihnen die
              Kontrolle über Ihre Daten zu geben.
            </p>
            <p>
              In dieser Datenschutzerklärung klären wir darüber auf, welche
              Daten von uns und zu welchem Zweck erhoben werden. Sie erfahren
              auch wie Sie Ihre Daten aktualisieren, verwalten, anfordern und
              löschen können.
            </p>
            <p>
              Wenn Sie Angebote von Damantis nutzen wollen, müssen Sie uns das
              Recht einräumen Ihre persönlichen Daten zu verarbeiten. Dieses
              Recht können Sie jederzeit widerrufen. Wir werden Ihre
              Einwilligung zur Datenverarbeitung protokollieren. Bei Bedarf
              teilen wir Ihnen mit, wann und wie Sie Ihre Einwilligung gegeben
              haben. Sollten Sie Ihre Zustimmung nicht erteilen, bitten wir um
              Verständnis, dass Sie das jeweilige Angebot gegebenenfalls nicht
              nutzen können. Unter personenbezogenen Daten sind gemäß Art. 4 Nr.
              1 DSGVO Informationen zu verstehen, die sich auf eine
              identifizierte oder identifizierbare natürliche Person beziehen.
              Bitte beachten Sie, dass Sie von unserer Seite auch per Hyperlinks
              zu anderen Anbietern, Kooperationspartnern und durch Werbung auf
              fremde Websites gelangen können, welche wiederum einem eigenen
              Datenschutz unterliegen.
            </p>
            <p>
              Damantis betreibt diverse Dienste und Websites. Diese
              Datenschutzerklärung gilt für die obenstehenden Angebote von uns.
            </p>
            <p>
              <strong>Welche Daten wir erheben</strong>
            </p>
            <p>
              Wenn Sie unsere Website besuchen, findet eine personenbezogene
              Auswertung Ihrer Daten findet nicht statt. Beim Besuch unserer
              Website werden lediglich standardmäßig die Adresse Ihres
              Internet-Service-Providers, wie IP-Adresse oder URL, der Name der
              Website von der aus Sie uns besucht haben, die Website, die Sie
              bei uns aufrufen sowie Datum und Dauer Ihres Aufenthaltes
              gespeichert. Außerdem werden der Status und die übertragene
              Datenmenge im Rahmen dieser Anfrage erfasst, darüber hinaus
              Produkt- und Versionsinformationen über den verwendeten Browser
              und das Betriebssystem Ihres Computers. Diese Erhebungen werden
              lediglich intern verarbeitet und nicht an Dritte weitergegeben.
              Auch werden somit keine persönlichen Nutzerprofile erstellt. Mit
              diesen Informationen werden lediglich intern beispielsweise
              Statistiken erhoben, welche Website am häufigsten frequentiert
              wird. So können wir besser verstehen, welche Informationen Sie bei
              uns suchen und finden wollen. Diese Daten werden regelmäßig
              gelöscht.
            </p>
            <p>
              Zur erfolgreichen Registrierung für Damantis-Dienste und damit zum
              Vertragsabschluss über deren Nutzung ist die Angabe
              personenbezogener Daten notwendig.
            </p>
            <p>
              <strong>Standardmäßig erheben wir bei der Registrierung</strong>
            </p>
            <p>
              Name, Vorname Geschlecht Geburtsdatum Email-Adresse Datum und
              Uhrzeit der Anmeldung IP-Adresse Zudem müssen Sie die Gültigkeit
              Ihrer Email-Adresse über einen Bestätigungslink in der Email
              bestätigen (Double Opt-In). Erst dann ist eine Registrierung
              vollständig abgeschlossen. Wir speichern die Uhrzeit, wann Sie auf
              den Bestätigungslink geklickt haben. Diese Daten erheben wir, um
              Sie als Person einwandfrei identifizieren und in korrekter Form
              ansprechen zu können. Wenn Sie sich bei Damantis registriert
              haben, sind Sie ein Damantis-Nutzer und werden automatisch in den
              Damantis-Newsletter eingetragen. Den Damantis-Newsletter nutzen
              wir, um all unsere Kunden zu kontaktieren und auf Neuerungen rund
              um Damantis aufmerksam zu machen. Es ist nicht möglich,
              Damantis-Nutzer zu sein, ohne den Newsletter zu erhalten.
            </p>
            <p>
              Wenn Sie bei uns ein kostenpflichtiges Produkt bestellen, sind
              weitere personenbezogenen Angaben notwendig. Dann erheben wir auch
              eine postalische Adresse und ein Zahlungsmittel, mit dem Sie die
              Bestellung bezahlen wollen.
            </p>
            <p>
              <strong>Wie wir die von uns erhobenen Daten nutzen</strong>
            </p>
            <p>
              All Ihre persönlichen Daten werden von uns vertraulich behandelt
              und grundsätzlich nicht an Dritte weitergegeben. Die persönlichen
              Daten werden lediglich zur Bereitstellung Ihrer angeforderten
              Dienste, zur Beantwortung Ihrer Fragen sowie zur internen
              Erstellung von Statistiken genutzt (Rechtsgrundlage Art. 6 Abs. 1
              S. 1 lit. f DSGVO). Ausnahmen bestehen lediglich bei von Ihnen
              angeforderten Diensten, welche eine Einwilligung zur Nutzung ihrer
              weiteren Daten beinhalten (Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit.
              a DSGVO) sowie bei der Weitergabe Ihrer Daten an staatliche
              Einrichtungen und Behörden per Gesetz (Rechtsgrundlage Art. 6 Abs.
              1 S. 1 lit. c DSGVO). Wir werden die mit ihrer Zustimmung
              erhobenen und gespeicherten Daten im Rahmen von vertraglichen
              Absprachen an Geschäftspartner und Sponsoren weitergeben, soweit
              dies zum Beispiel bei einem Gewinnspiel in Ihrem Interesse
              notwendig ist (Rechtsgrundlage Art. 6 Abs. 1 S. 1 lit. f DSGVO).
              Selbstverständlich sind unsere Geschäftspartner und Sponsoren
              aufgefordert, die gesetzlichen Datenschutzbestimmungen
              einzuhalten. Soweit wir auf unseren Internetseiten für andere
              Unternehmen werben, werden wir diese Werbung für Sie als Werbung
              kennzeichnen. Wir löschen personenbezogene Daten grundsätzlich
              dann, wenn diese zur Nutzung des Dienstes nicht mehr erforderlich
              sind, Sie Ihre Einwilligung zur Datenverarbeitung widerrufen,
              vorbehaltlich etwaiger gesetzlicher Aufbewahrungspflichten.
            </p>
            <p>
              <strong>
                Einige Beispiel für die Art der Datenverwendung sind:
              </strong>
            </p>
            <p>
              Zusendung einer Bestellbestätigung an Sie Anlegen eines
              Nutzerkontos auf der jeweiligen Website Bearbeitung von
              Support-Anfragen, die Sie an uns richten Wir bieten Ihnen die
              Möglichkeit an, unsere Tools und Dienstleistungen zu
              individualisieren. Zum Beispiel können Sie auf einfachaktien.de
              eigene Watchlisten oder Musterdepots anlegen.
            </p>
            <p>
              <strong>Ihre Rechte</strong>
            </p>
            <p>
              Rund um unserem Umgang mit Ihren persönlichen Daten haben Sie
              folgende Rechte:
            </p>
            <p>
              Auskunftsrecht (Art. 15 DSGVO) <br></br>Recht auf Berichtigung
              (Art. 16 DSGVO) <br></br>Widerspruchsrecht (Art. 21 DSGVO){' '}
              <br></br>Recht auf Löschung (Art. 17 DSGVO) <br></br>Recht auf
              Einschränkung der Verarbeitung (Art. 18f. DSGVO) <br></br>Recht
              auf Datenübertragbarkeit (Art. 20 DSGVO) <br></br>Bitte beachten
              Sie, dass wir bei derartigen Anfragen sicherstellen müssen, dass
              es sich tatsächlich um die betroffene Person handelt.
            </p>
            <p>
              <strong>Cookies</strong>
            </p>
            <p>
              Um Ihnen unsere Leistungen und Dienste effektiver zur Verfügung
              stellen zu können, nutzen wir Cookies. Hierunter sind kleine
              Datenpakete zu verstehen, welche temporär auf Ihrer Festplatte
              abgelegt werden und Ihre Identifizierung ermöglicht. Cookies
              sammeln Daten über die Nutzung unserer Website in anonymisierter
              Form - es werden keine Rückschlüsse auf Ihre Person zugelassen.
              Somit können wir ein hohes Maß an Benutzerfreundlichkeit
              ermöglichen. Zudem helfen uns Cookies bei der Identifizierung der
              beliebtesten Leistungen unserer Website. Je nach Einstellung Ihres
              Browsers werden Sie direkt auf Cookies hingewiesen oder dazu
              angehalten diese erst zuzulassen. Nichtsdestotrotz können Sie
              unsere Website auch ohne Cookies nutzen, indem Sie entsprechende
              Browsereinstellungen vornehmen. Bei einer Ablehnung von Cookies
              kann es indes dazu kommen, dass Sie vermehrt Werbung angezeigt
              bekommen, da diese über Cookies gesteuert wird. Zudem ist es
              möglich, dass Sie nicht den vollen Umfang des bereitgestellten
              Angebots auf unserer Seite nutzen können.
            </p>
            <p>
              <strong>
                Keine Weitergabe an Dritte und Auftragsdatenverarbeiter
              </strong>
            </p>
            <p>
              All Ihre persönlichen Daten werden von uns vertraulich behandelt
              und nicht an Dritte weitergegeben. Ausnahmen bestehen lediglich
              bei von Ihnen angeforderten Diensten, welche eine Einwilligung zur
              Nutzung ihrer weiteren Daten beinhalten sowie bei der Weitergabe
              Ihrer Daten an staatliche Einrichtungen und Behörden per Gesetz.
            </p>
            <p>
              Für die technische Bereitstellung unserer Dienste und deren
              Inhalte setzen wir teilweise Dritte als Dienstleister ein. Diese
              Dritten sind Auftragsverarbeiter, die die personenbezogenen Daten
              nach den jeweiligen Vorgaben und nur zu dem jeweils spezifischen
              Zweck verarbeiten. Die Damantis GmbH bleibt in diesem Zusammenhang
              für die Verarbeitung Verantwortlicher.
            </p>
            <p>
              <strong>
                Mit folgenden Firmen arbeiten wir zusammen, die dann teilweise
                Kontakt mit Ihren Daten bekommen
              </strong>
            </p>
            <p>
              Amazon Web Services, Inc. 410 Terry Avenue North Seattle WA 98109.
              United States. Amazon Web Services ist ein US-amerikanischer
              Cloud-Computing-Anbieter, der 2006 als Tochterunternehmen des
              Online-Versandhändlers Amazon.com gegründet wurde. Wir benutzen
              für den Betrieb der Website einfachaktien.de Rechenzentren von
              Amazon AWS. Das bedeutet, dass persönliche Daten von Ihnen auf
              Servern gespeichert sind, die in Rechenzentren von Amazon AWS
              stehen. Zendesk, Rheinsberger Straße 73, 10115 Berlin Zendesk ist
              ein Software-Unternehmen mit Hauptsitz in San Francisco,
              Kalifornien. Das Unternehmen vertreibt eine cloudbasierte
              Kundensupport-Plattform mit dem Namen Zendesk, die
              Helpdesk-Ticketing, Self-Service- und
              Kundenservice-Supportfunktionen bietet. Wir nutzen Zendesk als
              Ticketing-System, um zum Beispiel Kontaktanfragen über unsere
              Formulare effizient und effektiv für den Kunden zu bearbeiten. In
              der Zendesk-Cloud sind Ihr Name, Vorname und Ihre Email-Adresse
              gespeichert, sowie die Nachrichten, die Sie uns übermitteln.
              Logmein, Bloodstone Building, 1st Floor, Bloodstoney Road, Sir
              John Rogerson’s Quay, Dublin 2, Irland Logmein (GotoWebinar) ist
              ein US-amerikanisches Softwareunternehmen mit Sitz in Boston, das
              sich auf Fernwartungsdienste spezialisiert hat. Wir nutzen die
              GotoWebinar-Software von Logmein, um unsere Webinare
              durchzuführen. Hierzu ist eine Registrierung erforderlich. Es
              werden die mit einem Sternchen versehenen Pflichtangaben zum Zweck
              der Durchführung der Veranstaltung erhoben und verarbeitet;
              weitere Daten können vorteilhaft sein, sind aber nicht zwingend
              erforderlich (freiwillige Angaben). Rechtsgrundlage für die
              Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. b und a DSGVO.
              Mollie B.V.Keizersgracht 126, 1015 CW Amsterdam, Niederlande
              Mollie ist ein großer internationaler Zahlungsdienstleister für
              Onlinezahlungen. Wenn Sie kostenpflichtige Dienstleistungen über
              die Website einfachaktien.de in Anspruch nehmen, wickeln wir Ihre
              Zahlungen über Mollie ab. Alle Zahlungsvorgänge werden
              selbstverständlich unter höchsten Verschlüsselungsstandards
              übermittelt. Mailchimp, The Rocket Science Group LLC, 675 Ponce de
              Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA Mailchimp ist ein
              E-Mail Newsletter Provider, den wir für die Versendung von
              Newslettern verwenden. Der Versand der Newsletter erfolgt dabei
              verschlüsselt.{' '}
            </p>
            <p>
              <strong>Google Analytics</strong>
            </p>
            <p>
              Darüber hinaus nutzen wir Webanalysetools Dritter (z.B. Google
              Analytics, einen Webanalysedienst der Google, Inc. ("Google") und
              vergleichbare Webanalysedienste), für die bedarfsgerechte
              Gestaltung von Webangeboten. Hierzu setzen die Webanalysedienste
              auch Cookies ein (siehe Abschnitt Cookies).
            </p>
            <p>
              Die Auswertung und Aufbereitung dieser Daten erfolgt sowohl in
              Staaten der EU- als auch in Staaten, die nicht der EU angehören
              (z.B. Google Analytics in den USA).
            </p>
            <p>
              Die Webanalysedienste werden die übertragenen Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Websiteaktivitäten für uns zusammenzustellen und um weitere
              mit der Websitenutzung und der Internetnutzung verbundene
              Dienstleistungen zu erbringen. Auch werden die Webanalysedienste
              diese Informationen gegebenenfalls an Dritte übertragen, sofern
              dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
              Auftrag der Webanalysedienste verarbeiten. Die Webanalysedienste
              werden in keinem Fall Ihre IP-Adresse mit anderen Daten der
              Webanalysedienste in Verbindung bringen. Wir erhalten
              ausschließlich Auswertungen ohne Personenbezug. IP-Adressen werden
              ausschließlich anonymisiert gespeichert.
            </p>
            <p>
              Sie können die Erfassung Ihrer Daten durch Google Analytics
              verhindern, indem Sie auf folgenden Link klicken. Es wird ein
              Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
              zukünftigen Besuchen dieser Website verhindert: Google Analytics
              per Opt-Out-Cookie deaktivieren Google Adsense Auf unseren
              Websites verwenden wir den Online-Werbedienst Google AdSense,
              durch den Ihnen auf Ihre Interessen ausgelegte Werbung präsentiert
              werden kann. Wir verfolgen damit das Interesse, Ihnen Werbung
              anzuzeigen, die für Sie von Interesse sein könnte, um unsere
              Website für Sie interessanter zu gestalten. Hierzu werden
              statistische Informationen über Sie erfasst, die durch unsere
              Werbepartner verarbeitet werden. Diese Werbeanzeigen sind über den
              Hinweis "Google-Anzeigen" in der jeweiligen Anzeige erkennbar.
            </p>
            <p>
              Durch den Besuch unserer Websites erhält Google die Information,
              dass Sie unsere Website aufgerufen haben. Dazu nutzt Google einen
              Web Beacon, um einen Cookie auf Ihrem Rechner zu setzen. Es werden
              die unter "Logfiles" dieser Datenschutzerklärung genannten Daten
              übermittelt. Wir haben weder Einfluss auf die erhobenen Daten,
              noch ist uns der volle Umfang der Datenerhebung und die
              Speicherdauer bekannt. Ihre Daten werden in die USA übertragen und
              dort ausgewertet. Wenn Sie mit Ihrem Google-Account eingeloggt
              sind, können Ihre Daten diesem direkt zugeordnet werden. Wenn Sie
              die Zuordnung mit Ihrem Google-Profil nicht wünschen, müssen Sie
              sich ausloggen. Es ist möglich, dass diese Daten an
              Vertragspartner von Google an Dritte und Behörden weitergegeben
              werden. Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art.
              6 Abs. 1 S. 1 lit. f DSGVO. Diese Website hat auch Google
              AdSense-Anzeigen von Drittanbietern aktiviert. Die vorgenannten
              Daten können an diese Drittanbieter (benannt unter
              https://support.google.com/dfp_sb/answer/94149) übertragen werden.
            </p>
            <p>
              Sie können die Installation der Cookies von Google AdSense auf
              verschiedene Weise verhindern: a) durch eine entsprechende
              Einstellung Ihrer Browser-Software, insbesondere führt die
              Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von
              Drittanbietern erhalten; b) durch Deaktivierung der
              interessenbezogenen Anzeigen bei Google über den Link
              http://www.google.de/ads/preferences, wobei diese Einstellung
              gelöscht wird, wenn Sie Ihre Cookies löschen; c) durch
              Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die
              Teil der Selbstregulierungs-Kampagne "About Ads" sind, über den
              Link http://www.aboutads.info/choices, wobei diese Einstellung
              gelöscht wird, wenn Sie Ihre Cookies löschen; d) durch dauerhafte
              Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder
              Google Chrome unter dem Link
              http://www.google.com/settings/ads/plugin. Wir weisen Sie darauf
              hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen
              dieses Angebots vollumfänglich nutzen können.
            </p>
            <p>
              Weitere Informationen zu Zweck und Umfang der Datenerhebung und
              ihrer Verarbeitung sowie weitere Informationen zu Ihren
              diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze
              Ihrer Privatsphäre erhalten Sie bei: Google Inc., 1600
              Amphitheater Parkway, Mountainview, California 94043, USA;
              Datenschutzbedingungen für Werbung:
              http://www.google.de/intl/de/policies/technologies/ads. Google hat
              sich dem EU-US Privacy Shield unterworfen.
            </p>
            <p>
              Google Adwords Conversion Tracking Wir nutzen das Angebot von
              Google Adwords, um mit Hilfe von Werbemitteln (sogenannten Google
              Adwords) auf externen Webseiten auf unsere Angebote aufmerksam zu
              machen. Wir können in Relation zu den Daten der Werbekampagnen
              ermitteln, wie erfolgreich die einzelnen Werbemaßnahmen sind. Wir
              verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für
              Sie von Interesse ist, unsere Website für Sie interessanter zu
              gestalten und eine faire Berechnung von Werbe-Kosten zu erreichen.
            </p>
            <p>
              Diese Werbemittel werden durch Google über sogenannte "Ad Server"
              ausgeliefert. Dazu nutzen wir Ad Server Cookies, durch die
              bestimmte Parameter zur Erfolgsmessung, wie Einblendung der
              Anzeigen oder Klicks durch die Nutzer, gemessen werden können.
              Sofern Sie über eine Google-Anzeige auf unsere Website gelangen,
              wird von Google Adwords ein Cookie in ihrem PC gespeichert. Diese
              Cookies verlieren in der Regel nach 30 Tagen ihre Gültigkeit und
              sollen nicht dazu dienen, Sie persönlich zu identifizieren. Zu
              diesem Cookie werden in der Regel als Analyse-Werte die Unique
              Cookie-ID, Anzahl Ad Impressions pro Platzierung (Frequency),
              letzte Impression (relevant für Post-View-Conversions) sowie
              Opt-out-Informationen (Markierung, dass der Nutzer nicht mehr
              angesprochen werden möchte) gespeichert.
            </p>
            <p>
              Diese Cookies ermöglichen Google, Ihren Internetbrowser
              wiederzuerkennen. Sofern ein Nutzer bestimmte Seiten der Webseite
              eines Adwords-Kunden besucht und das auf seinem Computer
              gespeicherte Cookie noch nicht abgelaufen ist, können Google und
              der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat
              und zu dieser Seite weitergeleitet wurde. Jedem Adwords-Kunden
              wird ein anderes Cookie zugeordnet. Cookies können somit nicht
              über die Webseiten von Adwords-Kunden nachverfolgt werden. Wir
              selbst erheben und verarbeiten in den genannten Werbemaßnahmen
              keine personenbezogenen Daten. Wir erhalten von Google lediglich
              statistische Auswertungen zur Verfügung gestellt. Anhand dieser
              Auswertungen können wir erkennen, welche der eingesetzten
              Werbemaßnahmen besonders effektiv sind. Weitergehende Daten aus
              dem Einsatz der Werbemittel erhalten wir nicht, insbesondere
              können wir die Nutzer nicht anhand dieser Informationen
              identifizieren.
            </p>
            <p>
              Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser
              automatisch eine direkte Verbindung mit dem Server von Google auf.
              Wir haben keinen Einfluss auf den Umfang und die weitere
              Verwendung der Daten, die durch den Einsatz dieses Tools durch
              Google erhoben werden und informieren Sie daher entsprechend
              unserem Kenntnisstand: Durch die Einbindung von AdWords Conversion
              erhält Google die Information, dass Sie den entsprechenden Teil
              unseres Internetauftritts aufgerufen oder eine Anzeige von uns
              angeklickt haben. Sofern Sie bei einem Dienst von Google
              registriert sind, kann Google den Besuch Ihrem Account zuordnen.
              Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht
              eingeloggt haben, besteht die Möglichkeit, dass der Anbieter Ihre
              IP-Adresse in Erfahrung bringt und speichert.
            </p>
            <p>
              Sie können die Teilnahme an diesem Tracking-Verfahren auf
              verschiedene Weise verhindern: a) durch eine entsprechende
              Einstellung Ihrer Browser-Software, insbesondere führt die
              Unterdrückung von Drittcookies dazu, dass Sie keine Anzeigen von
              Drittanbietern erhalten; b) durch Deaktivierung der Cookies für
              Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass
              Cookies von der Domain "www.googleadservices.com" blockiert
              werden, https://www.google.de/settings/ads, wobei diese
              Einstellung gelöscht werden, wenn Sie Ihre Cookies löschen; c)
              durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter,
              die Teil der Selbstregulierungs-Kampagne "About Ads" sind, über
              den Link http://www.aboutads.info/choices, wobei diese Einstellung
              gelöscht wird, wenn Sie Ihre Cookies löschen; d) durch dauerhafte
              Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder
              Google Chrome unter dem Link
              http://www.google.com/settings/ads/plugin. Wir weisen Sie darauf
              hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen
              dieses Angebots vollumfänglich nutzen können. Rechtsgrundlage für
              die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO.
              Weitere Informationen zum Datenschutz bei Google finden Sie hier:
              http://www.google.com/intl/de/policies/privacy. Google hat sich
              dem EU-US Privacy Shield unterworfen.
            </p>
            <p>
              <strong>Datensicherheit</strong>
            </p>
            <p>
              Wir setzen umfangreiche technische sowie organisatorische
              Sicherheitsmaßnahmen ein, um Ihre persönlichen Daten vor
              vorsätzlichen Manipulationen, Verlust oder dem Zugriff durch
              unautorisierte Dritte zu schützen. Diese werden im Rahmen der
              geltenden Datenschutzgesetzte, entsprechend der technologischen
              Entwicklungen ständig verbessert. Dennoch möchten wir Sie darauf
              hinweisen, dass trotz unserer Sicherheitsmaßnahmen Informationen,
              welche Sie freiwillig über das Netz freigeben, von anderen genutzt
              werden können. So können wir bei Datenübertragungsfehlern sowie
              unautorisierten Zugriffen auf Ihre Daten durch Dritte keine
              Haftung übernehmen.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

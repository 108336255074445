import Header from '../Header';
import Footer from '../Footer';
import Newsletter from '../Newsletter';
export default function OptIn() {
  return (
    <>
      <Header />
      <Newsletter />
      <Footer />
    </>
  );
}

function CTA() {
    const openModal = (e) => {
      //display: block; padding-right: 17px;
      const modalWin = document.getElementById('newsletterModal');
      modalWin.classList.add('show');
      modalWin.style.display = 'block';
    };
    return (
      <section>
        <div className="container cta-grey">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 mx-auto text-center p-5">
              <p className="lead cta-text">
                Wir nutzen Technologie, um Aktienanalysen transparenter,
                einfacher und besser zu machen. Was das genau heißt, kannst du
                exklusiv in unserem Beispiel sehen.
              </p>
              <button
                className="btn theme-secondary-btn mt-50"
                onClick={openModal}
              >
                Testen
              </button>
            </div>
          </div>
        </div>
      </section>
    );
}

export default CTA;
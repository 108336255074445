import checkmark from '../Service/checkmark.svg';
import mail from './mail.svg';
import calendar from './calendar.svg';
import diamond from './diamond.svg';
import double from './double.svg';
import mailchimp from './mailchimp.svg';
import stats from './stats.svg';
function Newsletter() {
  return (
    <>
      <section id="home" className="hero-section">
        <div className="container">
          <div className="row align-items-center" id="hero-newsletter">
            <div className="col-xl-12 col-lg-12 text-center">
              <div className="hero-content-wrapper">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-6 text-center">
                    <p className="mb-25 wow fadeInDown" data-wow-delay=".2s">
                      Herzlich Willkommen beim Newsletter von einfachaktien. Wir
                      informieren dich, sobald wir Live gehen vermeiden.
                    </p>
                    <form
                      action="https://einfachaktien.us7.list-manage.com/subscribe/post"
                      method="POST"
                    >
                      <input
                        type="hidden"
                        name="u"
                        value="e6de86349be9b0c02c11c372b"
                      />
                      <input type="hidden" name="id" value="90d7d4f29a" />
                      <input
                        type="hidden"
                        name="ht"
                        value="6aa30e78f91fc73450dc9914cffe97ddec89f19a:MTYwNzUwNzA4MS43ODY1"
                      />
                      <input
                        type="hidden"
                        name="mc_signupsource"
                        value="hosted"
                      />

                      <label class="field field_v2">
                        <input
                          class="field__input"
                          type="text"
                          name="MERGE1"
                          id="MERGE1"
                          size="25"
                          placeholder="bsp. Max Muster"
                        />
                        <span class="field__label-wrap">
                          <span class="field__label">Name</span>
                        </span>
                      </label>
                      <label class="field field_v2">
                        <input
                          class="field__input"
                          type="email"
                          autocapitalize="off"
                          autocorrect="off"
                          name="MERGE0"
                          id="MERGE0"
                          size="25"
                          placeholder="bsp. max@muster.de"
                        />
                        <span class="field__label-wrap">
                          <span class="field__label">E-mail</span>
                        </span>
                      </label>
                      <p className="pt-2">
                        <input
                          type="submit"
                          className="btn theme-btn "
                          value="Abonnieren"
                        />
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div>
          <div className="container pt-50">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 mx-auto">
                <div className="section-title">
                  <h2 className="wow fadeInDown" data-wow-delay=".2s">
                    Hinweise zum Inhalt des Newsletters, dem Versandverfahren
                    und Statistiken
                  </h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center" id="dsvgo">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="service-box box-style">
                  <div className="service-icon box-icon-style">
                    <img src={mail} alt="einfachaktien" width="30" />
                  </div>
                  <div className="box-content-style service-content pt-20 text-left">
                    <h4>Mit welchen Inhalten kann ich rechnen?</h4>
                    <p>
                      Wir informieren dich zum Start der innovativen
                      Aktienplattform exklusiv und halten dich über Neuigkeiten,
                      Updates und Funktionen zu einfachaktien auf dem laufenden.
                      Du bekommst also nur wirklich wichtige Infos.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="service-box box-style">
                  <div className="service-icon box-icon-style">
                    <img src={calendar} alt="einfachaktien" width="30" />
                  </div>
                  <div className="box-content-style service-content pt-20 text-left">
                    <h4>Wie oft versendet ihr den Newsletter?</h4>
                    <p>
                      Wir versenden den einfachaktein Newsletter exklusiv bei
                      Launch von einfachaktien, sowie ausschließlich bei
                      wichtigen Informationen und Neuerungen bei einfachaktien,
                      wie beispielsweise neuen Funktionen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="service-box box-style">
                  <div className="service-icon box-icon-style">
                    <img src={mailchimp} alt="einfachaktien" width="30" />
                  </div>
                  <div className="box-content-style service-content pt-20 text-left">
                    <h4>Wir verwenden Mailchimp</h4>
                    <p>
                      Der Versand der Newsletter erfolgt durch Mailchimp. Dort
                      werden auch die Mailadressen und weitere Informationen zum
                      Versand und zur Analyse des Newsletters gespeichert
                      werden.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="service-box box-style">
                  <div className="service-icon box-icon-style">
                    <img src={double} alt="einfachaktien" width="30" />
                  </div>
                  <div className="box-content-style service-content pt-20 text-left">
                    <h4>Wir benutzen Double Opt-In und Opt-Out</h4>
                    <p>
                      Du erhältst nach Registrierung eine Double-Opt-In E-Mail,
                      in der wir dich um die Bestätigung deiner Anmeldung
                      bitten. So stellen wir sicher, dass es deine Mailadresse
                      ist. Du kannst den Newsletter natürlich über einen
                      Abmeldelink jederzeit abbestellen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="service-box box-style">
                  <div className="service-icon box-icon-style">
                    <img src={stats} alt="einfachaktien" width="30" />
                  </div>
                  <div className="box-content-style service-content pt-20 text-left">
                    <h4>Wir erstellen Statistiken</h4>
                    <p>
                      Damit wir dir immer die besten Informationen liefern
                      können, bist du mit der Auswertung der Newsletterdaten
                      einverstanden. Wir messen beispielsweise, wie häufig der
                      Newsletter geöffnet wird und welche Links angeklickt
                      werden.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="service-box box-style">
                  <div className="service-icon box-icon-style">
                    <img src={diamond} alt="einfachaktien" width="30" />
                  </div>
                  <div className="box-content-style service-content pt-20 text-left">
                    <h4>Datenschutzhinweise</h4>
                    <p>
                      Weitere ausführliche Informationen zu unserem
                      E-Mailversand, sowie zu allen sonstigen
                      Datenschutzmaßnahmen und auch zu den
                      Widerrufsmöglichkeiten findest du in unserer
                      Datenschutzerklärung.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Newsletter;

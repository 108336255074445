import Footer from '../Footer';
import Header from '../Header';
export default function Impressum() {
  return (
    <>
      <Header />
      <div className="container pt-50">
        <div className="row">
          <h1 className="mb-50">Impressum</h1>
          <div className="col-md-6">
            <h5>Angaben gemäß § 5 TMG</h5>
            <p>
              {' '}
              Damantis GmbH <br />
              Kemnastraße 3 <br />
              45657 Recklinghausen
              <br />
              <br />
              Vertreten durch: Geschäftsführer Jörn Schimanski{' '}
            </p>
            <br />
            <br />
            <h5>Kontakt</h5>
            <p>
              {' '}
              Telefon: +49 2361 849 8823
              <br />
              info@damantis.com <br />
              www.damantis.com{' '}
            </p>
            <br />
            <br />
            <h5>Registereintrag</h5>
            <p>
              {' '}
              Eintragung im Handelsregister
              <br />
              Registernummer: HRB 7103
              <br />
              Registergericht: Recklinghausen
              <br />
              <br />
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß §27 Umsatzsteuergesetz
              <br />
              DE292932760{' '}
            </p>
            <br />
            <br />
          </div>
          <div className="col-md-6">
            <h5>Haftung für Inhalte</h5>
            <p>
              {' '}
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
              Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
              verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
              jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
              Informationen zu überwachen oder nach Umständen zu forschen, die
              auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
              Entfernung oder Sperrung der Nutzung von Informationen nach den
              allgemeinen Gesetzen bleiben hiervon unberührt. Eine
              diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
              Kenntnis einer konkreten Rechtsverletzung möglich. Bei
              Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
              diese Inhalte umgehend entfernen.{' '}
            </p>
            <br />
            <br />
            <h5>Urheberrecht</h5>
            <p>
              {' '}
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht
              kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
              Seite nicht vom Betreiber erstellt wurden, werden die
              Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
              Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
              Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
              entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
              werden wir derartige Inhalte umgehend entfernen.{' '}
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import Copyright from "./copyright";
import Logo from '../../component/Logo'
import fb from './fb.svg';
import twitter from './twitter.svg'
import { Link } from "react-router-dom";
function Footer() {
        return (
          <>
            <footer className="footer pt-200">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-8 col-md-6">
                    <div
                      className="footer-widget wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <a href="/" className="logo">
                        <Logo />
                      </a>
                    </div>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-6 text-right">
                    <div
                      className="footer-widget wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <a
                        href="https://www.facebook.com/einfachaktien"
                        className="logo"
                      >
                        <img src={fb} alt="logo" height="35" />
                      </a>

                      <a
                        href="https://twitter.com/einfachaktien"
                        className="logo ml-2"
                      >
                        <img src={twitter} alt="logo" height="35" />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="row mt-10 pt-4 justify-content-between"
                  style={{ borderTop: '1px solid #ddd' }}
                >
                  <div className="col-xl-6 col-lg-6 col-md-6">
                    <div
                      className="footer-widget mb-60 wow fadeInLeft"
                      data-wow-delay=".2s"
                    >
                      <p className="mb-30 footer-desc">
                        <strong>
                          Einfachaktien ist eine Marke der Damantis GmbH
                        </strong>
                      </p>
                      <p className="footer-desc">
                        <strong>Damantis GmbH</strong>
                        <br />
                        Kemnastraße 3<br />
                        45657 Recklinghausen <br />
                        <br />
                        T: +49 2361 849 8830
                        <br />
                        E-Mail: info (at) einfachaktien.de
                      </p>
                    </div>
                  </div>
                 
                  <div class="col-xl-6 col-lg-6 col-md-6 ">
                    <div
                      class="footer-widget mb-60 wow fadeInUp"
                      data-wow-delay=".6s"
                    >
                      <p className="mb-30 footer-desc">
                        <strong>Rechtliches</strong>
                      </p>
                      <ul class="footer-links">
                        <li>
                          <Link to="impressum">Impressum</Link>
                        </li>
                        <li>
                          <Link to="datenschutz">Datenschutz</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
            <Copyright />
          </>
        );
}

export default Footer;

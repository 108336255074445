import Logo from '../../component/Logo';
import close from './close.svg';
import { Link } from 'react-router-dom';

function ModalA() {
  const closeModal = (e) => {
    console.log('close');
    const modalWin = document.getElementById('newsletterModal');
    modalWin.classList.remove('show');
    modalWin.style.display = 'none';
    const backdrop = document.getElementsByClassName('modal-backdrop');
    if (backdrop[0]) {
      backdrop[0].remove();
    }
  };
  return (
    <div
      className="modal fade"
      id="newsletterModal"
      tabindex="-1"
      aria-labelledby="newsletterModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-fullscreen ">
        <div className="modal-content">
          <div className="background-image"></div>
          <button
            type="button"
            class="bloseBtn"
            onClick={closeModal}
            aria-label="Close"
          >
            <img src={close} />
          </button>
          <div
            className="h-100 row align-items-center full"
            style={{ zIndex: '2;' }}
          >
            <div className="fullC">
              <div className="row h-100">
                <div className="col-sm-12 col-md-6 col-lg-6 my-auto mx-auto d-flex justify-content-center">
                  <div className="newsletter-win text-center">
                    <Logo />
                    <p className="pt-2 mt-2">
                      Herzlich Willkommen beim Newsletter von einfachaktien.
                      <br />
                      Wir informieren dich, sobald wir Live gehen
                    </p>
                    <p>
                      Bitte gib dazu deinen Namen und deine E-Mail Adresse ein.
                    </p>
                    <div className="row justify-content-center">
                      <div className="col-12 col-sm-12 col-md-6">
                        <form
                          action="https://einfachaktien.us7.list-manage.com/subscribe/post"
                          method="POST"
                        >
                          <input
                            type="hidden"
                            name="u"
                            value="e6de86349be9b0c02c11c372b"
                          />
                          <input type="hidden" name="id" value="90d7d4f29a" />
                          <input
                            type="hidden"
                            name="ht"
                            value="6aa30e78f91fc73450dc9914cffe97ddec89f19a:MTYwNzUwNzA4MS43ODY1"
                          />
                          <input
                            type="hidden"
                            name="mc_signupsource"
                            value="hosted"
                          />

                          <label class="field field_v2">
                            <input
                              class="field__input"
                              type="text"
                              name="MERGE1"
                              id="MERGE1"
                              size="25"
                              autocomplete="off"
                              placeholder="bsp. Max Muster"
                            />
                            <span class="field__label-wrap">
                              <span class="field__label">Name</span>
                            </span>
                          </label>
                          <label class="field field_v2">
                            <input
                              class="field__input"
                              type="email"
                              autocapitalize="off"
                              autocorrect="off"
                              autocomplete="off"
                              name="MERGE0"
                              id="MERGE0"
                              size="25"
                              placeholder="bsp. max@muster.de"
                            />
                            <span class="field__label-wrap">
                              <span class="field__label">E-mail</span>
                            </span>
                          </label>
                          <p className="pt-2">
                            <input
                              type="submit"
                              className="btn theme-btn "
                              value="Abonnieren"
                            />
                          </p>
                          <div class="form-text">
                            <a href="newsletter" class="form-text">
                              Hinweise zum Inhalt des Newsletters, dem
                              Versandverfahren, Datenschutz und Statistiken
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalA;

import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from '../HomePage';
import OptIn from '../OptIn';
import Impressum from '../Impressum'
import Datenschutz from '../Datenschutz';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" component={HomePage} exact />
      <Route path="/impressum" component={Impressum} exact />
      <Route path="/datenschutz" component={Datenschutz} exact />
      <Route path="/newsletter" component={OptIn} exact />
    </Switch>
  );
}

import { Link } from 'react-router-dom';
import Logo from '../../component/Logo';

const openModal = e => {
  //display: block; padding-right: 17px;
  const modalWin = document.getElementById('newsletterModal');
  modalWin.classList.add('show');
  modalWin.style.display = 'block';

}

function Header() {
  return (
    <div className="container">
      <div className="row align-items-center">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <Link className="navbar-brand" to="" alt="home">
            <Logo width="50" alt="einfachaktien-logo" />
            <span className="navBrand">
              einfach<span className="subBrand">aktien</span>
            </span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="d-flex ml-auto">
              <span className="p-1">
                <button
                  className="btn theme-btn border-btn"
                  onClick={openModal}
                >
                  Anmelden
                </button>
              </span>
              <span className="p-1">
                <button className="btn theme-btn" onClick={openModal}>
                  Jetzt registrieren
                </button>
              </span>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;

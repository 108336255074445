function Hero() {
  const openModal = (e) => {
    //display: block; padding-right: 17px;
    const modalWin = document.getElementById('newsletterModal');
    modalWin.classList.add('show');
    modalWin.style.display = 'block';
  };

  return (
    <section id="home" className="hero-section">
      <div className="container">
        <div className="row align-items-center" id="hero">
          <div className="col-xl-12 col-lg-12 text-center">
            <div className="hero-content-wrapper">
              <h1 className="wow fadeInDown" data-wow-delay=".2s">
                Eine vollständige Aktienanalyse.
              </h1>
              <h3>
                <strong>Innerhalb weniger Sekunden</strong>
              </h3>
              <h3 className="mt-150 wow fadeInDown">Neugierig?</h3>
              <button
                className="btn theme-secondary-btn  wow fadeInDown"
                onClick={openModal}
              >
                Testen
              </button>
              <h4>
                <small className="smallHead  wow fadeInDown">
                  Hier im Beispiel an der RWE Aktie
                </small>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;

import analyzeImg from './New_Analysieren.png'
import discoverImg from './New_Entdecken.png'
import knowImg from './landingpage3.jpg'
function Content() {
  return (
    <>
      <section className="pt-50">
        <div className="exp-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 mx-auto">
                <div className="section-title">
                  <h2 className="wow fadeInDown" data-wow-delay=".2s">
                    Profitiere von moderner Technologie.
                  </h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center pt-50">
              <div className="col-xl-6 col-lg-6 ">
                <img
                  src={analyzeImg}
                  alt="Analysiere"
                  className="img-fluid wow fadeInDown"
                  width="600"
                />
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="about-content-wrapper p-10">
                  <div className="section-title">
                    <span className="wow fadeInUp" data-wow-delay=".2s"></span>
                    <h2 className="mb-40 wow fadeInRight" data-wow-delay=".4s">
                      Analysiere
                    </h2>
                  </div>
                  <div className="about-content">
                    <p className="mb-45 wow fadeInUp" data-wow-delay=".6s">
                      Erhalte eine unabhängige und objektive Analyse aller
                      wichtigen Daten der Unternehmen. Analysiere
                      Geschäftsberichte und Fundamentaldaten und lasse dir alle
                      relevanten Kennzahlen anzeigen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="exp-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6">
                <div className="about-content-wrapper p-10">
                  <div className="section-title">
                    <span className="wow fadeInUp" data-wow-delay=".2s"></span>
                    <h2 className="mb-40 wow fadeInRight" data-wow-delay=".4s">
                      Entdecke
                    </h2>
                  </div>
                  <div className="about-content">
                    <p className="mb-45 wow fadeInUp" data-wow-delay=".6s">
                      Browse durch tausende von Aktien, gefiltert nach deinen
                      Kriterien und suche dir die besten aus. Vergleiche Aktien
                      aus deinem Depot und entdecke neue attraktive Investments.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <img src={discoverImg} alt="entdecke" className="img-fluid" width="600" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="exp-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6">
                <img
                  src={knowImg}
                  className="img-fluid"
                  alt="Verstehe"
                  width="600"
                />
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="about-content-wrapper p-10">
                  <div className="section-title">
                    <span className="wow fadeInUp" data-wow-delay=".2s"></span>
                    <h2 className="mb-40 wow fadeInRight" data-wow-delay=".4s">
                      Verstehe
                    </h2>
                  </div>
                  <div className="about-content">
                    <p className="mb-45 wow fadeInUp" data-wow-delay=".6s">
                      Unsere Anmerkungen und Visualisierungen machen es dir
                      leicht zu verstehen, wie ein Unternehmen aufgestellt und
                      bewertet ist. So erkennst du eine gute Investition.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;

import checkmark from './checkmark.svg'

function Service() {
  return (
    <section id="service" className="service-section pt-130 pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="service-box box-style">
              <div className="service-icon box-icon-style">
                <img src={checkmark} alt="einfachaktien" width="30" />
              </div>
              <div className="box-content-style service-content pt-20">
                <h4>Objektive Analysen durch Algorithmen</h4>
                <p>
                  Unsere innovative und ganzheitliche Aktienanalyse ermöglicht
                  dir ein vollständiges Bild über die Unternehmen und deren
                  Aktien.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-box box-style">
              <div className="service-icon box-icon-style">
                <img src={checkmark} alt="einfachaktien" width="30" />
              </div>
              <div className="box-content-style service-content pt-20">
                <h4>Erstklassige Visualisierungen</h4>
                <p>
                  Ein Bild sagt mehr als tausend Worte. Aus diesem Grund
                  erstellen wir aus den Fundamentaldaten einfach und
                  verständliche Grafiken und Darstellungen.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-box box-style">
              <div className="service-icon box-icon-style">
                <img src={checkmark} alt="einfachaktien" width="30" />
              </div>
              <div className="box-content-style service-content pt-20">
                <h4>Erläuterungen zu allen Daten</h4>
                <p>
                  Du erhältst Erklärungen und Erläuterungen zu allen Daten. So
                  weißt du jederzeit, welche Daten wichtig sind, und warum.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="service-box box-style">
              <div className="service-icon box-icon-style">
                <img src={checkmark} alt="einfachaktien" width="30" />
              </div>
              <div className="box-content-style service-content pt-20">
                <h4>
                  Kein
                  <br />
                  Fachchinesisch
                </h4>
                <p>
                  Weil es genug Websites mit Analysen gibt, die nur Banker
                  verstehen, machen wir es anders. Wir reden Klartext, einfach
                  und verständlich.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Service;

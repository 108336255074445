function Copyright() {
    return (
      <div className="copyright-area">
        <div className="row align-items-center">
          <div className="col-md-12 text-center">
            <p className="wow fadeInUp" data-wow-delay=".3s">
              ©Damantis GmbH 2020. Alle Rechte vorbehalten. Die Geldanlage an
              den Finanzmärkten ist mit Risiken verbunden. Lesen Sie dazu
              unseren Risikohinweis.
            </p>
          </div>
        </div>
      </div>
    );
}

export default Copyright;
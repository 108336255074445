function Intro() {
    return (
      <section className="intro-section pt-50">
        <div className="container wow fadeInDown">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <h2 className="section-title">
                In Aktien investieren? Klar, aber in die richtigen.
              </h2>
              <p className="lead section-content mt-30">
                einfachaktien bietet dir Informationen, Fundamentaldaten und
                Kennzahlen zu tausenden von Aktien . Durch intelligente
                Visualisierungen und verständliche Anmerkungen heben wir die
                Aktienanalyse und Darstellung der Daten auf ein neues Level. Die
                Daten werden durch unsere Algorithmen so aufbereitet, dass sie
                jeder versteht. Und das sieht auch noch richtig gut aus.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Intro;
import Header from '../Header';
import Hero from '../Hero';
import Intro from '../Intro';
import Content from '../Content';
import CTA from '../CTA';
import Service from '../Service';
import Footer from '../Footer';
import ModalA from '../Modal';
 
export default function HomePage() {
 
  return (
    <>
      <ModalA />
      <Header />
      <Hero />
      <Intro />
      <Content />
      <CTA />
      <Service />
      <Footer />
    </>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './assets/css/bootstrap-5.0.0-alpha.min.css';
import './assets/css/LineIcons.2.0.css';
import './assets/css/animate.css';
import './assets/css/main.css';

import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';

import App from './container/App';
import reportWebVitals from './reportWebVitals';

const amplifyConfig = {
  Auth: {
    identityPoolId: 'us-east-1:ddad5063-3786-4510-a2eb-bc397ffb3adb',
    region: 'eu-central-1',
  },
};
Auth.configure(amplifyConfig);
const analyticsConfig = {
  AWSPinpoint: {
    appId: '05594c0b961a409e9c46c9f0710c22de',
    region: 'eu-central-1',
    mandatorySignIn: false,
  },
};
Analytics.configure(analyticsConfig);
Analytics.record('visit');
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
